var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow bg-sidebar",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded"},[_vm._t("header",function(){return [_c('div',{staticClass:"nav-item row justify-content-end px-1 pt-1"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle"},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"24"},on:{"click":_vm.toggleVerticalMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon mr-0",attrs:{"icon":_vm.collapseTogglerIconFeather,"size":"24"},on:{"click":_vm.toggleCollapsed}})],1)],1),_c('div',{staticClass:"nav-item row",class:{
          'justify-content-center':
            !_vm.isVerticalMenuCollapsed ||
            (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
        },staticStyle:{"position":"relative","bottom":"1rem","padding-left":"0.5rem"}},[_c('b-link',{staticClass:"navbar-brand mr-0",attrs:{"to":"/"}},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":_vm.appLogoImage,"alt":"logo"}})],1)])],1)]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }}},[(_vm.isMenuMedicalCenter)?_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main bg-sidebar",attrs:{"items":_vm.navMenuItemsMedicalCenter}}):_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main bg-sidebar",attrs:{"items":_vm.navMenuItems}})],1),_c('div',{staticClass:"w-100 text-center mt-1"},[_c('b-img',{staticClass:"img-fluid mx-auto",attrs:{"src":require("@/assets/images/logo/logoInnova_horizontal_white.svg"),"alt":"Login V2","width":"100px"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }