export default [
    {
        header: 'Modules',
        action: 'index',
        resource: 'module_modules',
    },
    {
        title: 'Type of Modules',
        icon: 'AirplayIcon',
        action: 'index',
        resource: 'module_modules.type_of_modules',
        children: [
            {
                title: 'Promotions',
                icon: 'GiftIcon',
                route: 'module-promotion',
                action: 'index',
                resource: 'module_modules.type_of_modules.promotions',
            },
            {
                title: 'Used Cupons',
                route: 'web-settings-used-cupons',
                icon: 'BookmarkIcon',
                action: 'index',
                resource: 'module_modules.type_of_modules.used_cupons',
            },
            {
                title: 'Survey',
                icon: 'FileTextIcon',
                tagVariant: 'light-warning',
                route: 'module-survey',
                action: 'index',
                resource: 'module_modules.type_of_modules.survey',
            },
            {
                title: 'Inventory',
                icon: 'FolderPlusIcon',
                tagVariant: 'light-warning',
                action: 'index',
                resource: 'module_modules.type_of_modules.inventory',
                children: [
                    {
                        title: 'Status',
                        icon: 'GridIcon',
                        route: 'module-inventory-item-status',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.inventory.status',
                    },
                    {
                        title: 'Category',
                        icon: 'GridIcon',
                        route: 'module-inventory-item-category',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.inventory.category',
                    },
                    {
                        title: 'Presentation',
                        icon: 'GridIcon',
                        route: 'module-inventory-item-presentation',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.inventory.presentation',
                    },
                    {
                        title: 'Item',
                        icon: 'GridIcon',
                        route: 'module-inventory-item',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.inventory.item',
                    },
                    {
                        title: 'Stock',
                        icon: 'GridIcon',
                        route: 'module-inventory-stock',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.inventory.item_stock',
                    },
                    {
                        title: 'Add New Order',
                        icon: 'GridIcon',
                        route: 'module-inventory-order',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.inventory.order',
                    },
                    {
                        title: 'Clinic Stock',
                        icon: 'GridIcon',
                        route: 'module-inventory-stock-facility',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.inventory.clinic_stock',
                    }
                    /* {
                        title: 'Setting',
                        icon: 'GridIcon',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.inventory.settings',
                        children: [
                            {
                                title: 'Stock setting',
                                icon: 'GridIcon',
                                route: 'admin-inventory-product-details',
                                action: 'index',
                                resource: 'module_modules.type_of_modules.inventory.settings.stock_settings',
                            },
                            {
                                title: 'Items Admin',
                                icon: 'GridIcon',
                                route: 'admin-inventory-product-details',
                                action: 'index',
                                resource: 'module_modules.type_of_modules.inventory.settings.items_admin',
                            },
                        ]
                    }, */
                ],
            },
            {
                title: 'Membership',
                icon: 'CreditCardIcon',
                tagVariant: 'light-warning',
                action: 'index',
                resource: 'module_modules.type_of_modules.memberships',
                children: [
                    {
                        title: 'Types of Membership',
                        icon: 'GridIcon',
                        route: 'module-memberships-types',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.memberships.types',
                    },
                    {
                        title: 'Membership',
                        icon: 'UsersIcon',
                        route: 'module-memberships',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.memberships.membership',
                    },
                    {
                        title: 'Lots',
                        icon: 'GridIcon',
                        route: 'module-memberships-lotes',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.memberships.lotes',
                    },
                    // {
                    //     title: 'Codes',
                    //     icon: 'GridIcon',
                    //     route: 'module-memberships-code',
                    //     action: 'index',
                    //     resource: 'module_modules.type_of_modules.memberships.codes',
                    // },
                    {
                        title: 'Users',
                        icon: 'UsersIcon',
                        route: 'module-memberships-users',
                        action: 'index',
                        resource: 'module_modules.type_of_modules.memberships.users',
                    }
                ]
            },
        ]
    },

]