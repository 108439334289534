export default [
    {
        header: 'Payment',
        action: 'index',
        resource: 'module_payment', //nurse no puede ver esta seccion
    },
    {
        title: 'Invoice List',
        icon: 'FileTextIcon',
        tagVariant: 'light-primary',
        route: 'resource-schedule',
        action: 'index',
        resource: 'module_payment.invoice_list',
    },
    {
        title: 'Daily Closing',
        icon: 'TrendingUpIcon',
        tagVariant: 'light-primary',
        action: 'index',
        resource: 'module_payment.daily_closing',
        children: [
            {
                title: 'Run Closing',
                route: 'payment-cash-account',
                icon: 'RefreshCwIcon',
                action: 'index',
                resource: 'module_payment.daily_closing.run_closing',
            },
            {
                title: 'Daily Boxes',
                route: 'payment-cash-daily-boxes',
                icon: 'InboxIcon',
                action: 'index',
                resource: 'module_payment.daily_closing.daily_boxes',
            },
            {
                title: 'Filter Boxes',
                route: { name: 'payment-cash-history' },
                icon: 'InboxIcon',
                action: 'index',
                resource: 'module_payment.daily_closing.daily_boxes',
            },
        ]
    },
    {
        title: 'Refunds',
        route: 'payment-refund',
        icon: 'ToggleRightIcon',
        action: 'index',
        resource: 'module_payment.refunds',
    },
]