export default [
  {
    title: 'DASHBOARD',
    icon: 'HomeIcon',
    bgVariant: 'true',
    route: 'dashboard-analytics',
    action: 'index',
    resource: 'module_dashboard',
  },
]
