export default [
  {
    header: 'Data and Reports',
    action: 'index',
    resource: 'module_data_and_reports',
  },
  {
    title: 'Admin Data',
    icon: 'BarChart2Icon',
    tagVariant: 'light-warning',
    route: 'report-admin-data',
    action: 'index',
    resource: 'module_data_and_reports.admin_data',
  },
  {
    title: 'Bonus Data',
    icon: 'GiftIcon',
    tagVariant: 'light-warning',
    route: 'report-bonus-data',
    action: 'index',
    resource: 'module_data_and_reports.admin_data',
  },
  {
    title: 'Semaglutide',
    icon: 'CoffeeIcon',
    tagVariant: 'light-warning',
    route: 'report-semaglutide',
    action: 'index',
    resource: 'module_data_and_reports.admin_data',
  },
  {
    title: 'RCM',
    route: 'report-rcm',
    icon: 'EyeIcon',
    action: 'index',
    resource: 'module_data_and_reports.rcm',
  },
  {
    title: 'Procedure Data',
    icon: 'BarChart2Icon',
    tagVariant: 'light-warning',
    route: 'report-procedure-data',
    action: 'index',
    resource: 'module_data_and_reports.admin_data',
  },
  
]