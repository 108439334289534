export default [
    {
        header: 'System Settings',
        action: 'index',
        resource: 'module_system_settings',
    },
    {
        title: 'Settings',
        icon: 'SettingsIcon',
        tagVariant: 'light-primary',
        action: 'index',
        resource: 'module_system_settings.settings',
        children: [
            {
                icon: 'UserPlusIcon',
                title: 'Admin User',
                action: 'index',
                resource: 'module_system_settings.settings.admin_user',
                children: [
                    {
                        icon: 'UsersIcon',
                        title: 'Types of Users',
                        route: 'web-settings-users',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_user.type_of_user',
                    },
                    {
                        icon: 'ShieldIcon',
                        title: 'User Roles',
                        route: 'web-settings-roles',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_user.user_roles',
                    },
                    {
                        title: 'Promoters',
                        route: 'web-settings-promoter',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_user.promoters',
                    },
                    {
                        title: 'Goals',
                        route: 'web-settings-goals',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_user.goals',
                    },
                    {
                        title: 'Goals Types',
                        route: 'web-settings-types-goals',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_user.goal_types',
                    },
                    {
                        title: 'Quantifications',
                        route: 'web-settings-quantifications',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_user.goal_quantifications',
                    },

                ]
            },
            {
                title: 'Admin Facility',
                action: 'index',
                icon: 'HomeIcon',
                resource: 'module_system_settings.settings.admin_facility',
                children: [
                    {
                        title: 'Types of Facilities',
                        icon: 'HomeIcon',
                        route: 'web-settings-facilities',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_facility.facilities',
                    },
                    {
                        title: 'Regions',
                        icon: 'MapIcon',
                        route: 'web-settings-regions',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_facility.regions',
                    },
                    {
                        title: 'States',
                        icon: 'MapIcon',
                        route: 'web-settings-states',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_facility.states',
                    },
                    {
                        title: 'Cities',
                        icon: 'MapIcon',
                        route: 'web-settings-cities',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_facility.cities',
                    }
                ]
            },
            {
                title: 'Admin Visit',
                icon: 'PenToolIcon',
                action: 'index',
                resource: 'module_system_settings.settings.admin_visit',
                children: [
                    {
                        title: 'Types of visits',
                        route: 'web-settings-visit',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_visit.types_of_visit',
                    },
                    {
                        title: 'Visit Reason',
                        route: 'web-settings-consult',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_visit.visit_reason',
                    },
                    {
                        title: 'Visit Status',
                        route: 'web-settings-visit-status',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_visit.visit_status',
                    },
                    {
                        title: 'Claim Status',
                        route: 'web-settings-claim-status',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_visit.claim_status',
                    },
                ]
            },
            {
                icon: 'FileMinusIcon',
                title: 'Admin Subject',
                action: 'index',
                resource: 'module_system_settings.settings.admin_subject',
                children: [
                    {
                        title: 'Review System',
                        route: 'web-settings-review-system',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_subject.review_system',
                    },
                    {
                        title: 'Review Category',
                        route: 'web-settings-category-review-system',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_subject.review_system_category',
                    },
                ]
            },
            {
                icon: 'PackageIcon',
                title: 'Admin Treatment',
                action: 'index',
                resource: 'module_system_settings.settings.admin_treatments',
                children: [
                    {
                        title: 'Treatments',
                        route: 'web-settings-treatment',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_treatments.treatments',
                    },
                ]
            },
            {
                icon: 'FeatherIcon',
                title: 'Admin Diagnoses',
                action: 'index',
                resource: 'module_system_settings.settings.admin_diagnoses',
                children: [
                    {
                        title: 'Diagnoses',
                        route: 'web-settings-diagnoses',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_diagnoses.diagnoses',
                    },
                ]
            },
            {
                icon: 'EditIcon',
                title: 'Admin Form',
                action: 'index',
                resource: 'module_system_settings.settings.admin_form',
                children: [
                    {
                        title: 'Medications',
                        route: 'web-settings-medication',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_form.medications',
                    },
                    {
                        title: 'Allergies',
                        route: 'web-settings-allergies',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_form.allergies',
                    },
                    {
                        title: 'Ailments',
                        route: 'web-settings-ailment',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_form.ailments',
                    },
                ]
            },
            {
                icon: 'ClipboardIcon',
                title: 'In House',
                action: 'index',
                resource: 'module_system_settings.settings.in_house',
                children: [
                    {
                        title: 'IH Tests',
                        route: 'web-settings-test',
                        action: 'index',
                        resource: 'module_system_settings.settings.in_house.ih_tests',
                    },
                    {
                        title: 'IH Procedures',
                        route: 'web-settings-procedures',
                        action: 'index',
                        resource: 'module_system_settings.settings.in_house.ih_procedures',
                    },
                ]
            },
            {
                icon: 'CpuIcon',
                title: 'Laboratory',
                action: 'index',
                resource: 'module_system_settings.settings.laboratory',
                children: [
                    {
                        title: 'Categories',
                        route: 'web-settings-category_lab',
                        action: 'index',
                        resource: 'module_system_settings.settings.laboratory.categories',
                    },
                    {
                        title: 'Laboratories',
                        route: 'web-settings-laboratory',
                        action: 'index',
                        resource: 'module_system_settings.settings.laboratory.laboratories',
                    },
                ]
            },
            {
                icon: 'ImageIcon',
                title: 'Imaging',
                action: 'index',
                resource: 'module_system_settings.settings.imaging',
                children: [
                    {
                        title: 'US',
                        route: 'web-settings-diagnostic-image',
                        action: 'index',
                        resource: 'module_system_settings.settings.imaging.diagnostic_imaging',
                    },
                    {
                        title: 'US Category',
                        route: 'web-settings-diagnostic-imaging-category',
                        action: 'index',
                        resource: 'module_system_settings.settings.imaging.categories',
                    },
                ]
            },
            {
                title: 'Consult Price',
                route: 'web-settings-consult-price',
                icon: 'DollarSignIcon',
                action: 'index',
                resource: 'module_system_settings.settings.consult_price',
            },
            {
                icon: 'ToolIcon',
                title: 'Web Settings',
                action: 'index',
                resource: 'module_system_settings.settings.web_settings',
                children: [
                    {
                        icon: 'ToolIcon',
                        title: 'Template',
                        route: 'web-settings-template',
                        action: 'index',
                        resource: 'module_system_settings.settings.web_settings.template',
                    }
                ]
            },
            /* Admin Security */
            {
                icon: 'ToolIcon',
                title: 'Admin Security',
                action: 'index',
                resource: 'module_system_settings.settings.web_settings',
                children: [
                    {
                        icon: 'ToolIcon',
                        title: 'Fail Sessions',
                        route: 'web-settings-fail-sessions',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_user.type_of_user',
                    }
                ]
            },
            /* Packages */
            {
                icon: 'PackageIcon',
                title: 'Admin Packages',
                action: 'index',
                resource: 'module_system_settings.settings.web_settings',
                children: [
                    {
                        icon: 'PackageIcon',
                        title: 'Packages',
                        route: 'web-settings-procedure-packages',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_user.type_of_user',
                    },
                    {
                        icon: 'PackageIcon',
                        title: 'Packages Membership',
                        route: 'web-settings-procedure-packages-membership',
                        action: 'index',
                        resource: 'module_system_settings.settings.admin_user.type_of_user',
                    }
                ]
            },
        ]
    }

]