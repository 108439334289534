export default [
    {
        header: 'CRM 360',
        action: 'index',
        resource: 'module_360crm',
    },
    {
        title: 'Boards',
        icon: 'ColumnsIcon',
        route: 'crm-board',
        tagVariant: 'light-primary',
        action: 'index',
        resource: 'module_360crm.boards',
    },
    {
        title: 'CRM Admin',
        icon: 'PhoneCallIcon',
        tagVariant: 'light-warning',
        action: 'index',
        resource: 'crm_admin',
        children: [
            {
                title: 'Labels',
                route: 'crm-admin-labels',
                action: 'index',
                resource: 'module_360crm.crm_admin.labels',
            },
            {
                title: 'Card Status',
                route: 'crm-admin-card-status',
                icon: 'PieChartIcon',
                action: 'index',
                resource: 'module_360crm.crm_admin.card_status',
            },
            {
                title: 'Boards',
                route: 'crm-admin-boards',
                icon: 'ColumnsIcon',
                action: 'index',
                resource: 'module_360crm.crm_admin.board',
            },
            {
                title: 'Columns',
                route: 'crm-admin-columns',
                icon: 'LayoutIcon',
                action: 'index',
                resource: 'module_360crm.crm_admin.column',
            },
        ]
    }
]