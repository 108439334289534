export default [
    {
        header: 'Help Center',
        action: 'index',
        resource: 'module_help_center',
    },
    {
        title: 'Learning Center',
        tagVariant: 'light-primary',
        icon: 'BookOpenIcon',
        action: 'index',
        resource: 'module_help_center.learning_center',
        children: [
            {
                title: '360 Tutorials',
                route: 'help-center-tutorial',
                icon: 'BookIcon',
                tagVariant: 'light-primary',
                action: 'index',
                resource: 'module_help_center.learning_center.360tutorials',
            },
            {
                title: 'Tutorial',
                tagVariant: 'light-primary',
                icon: 'FilmIcon',
                route: 'help-center-information',
                action: 'index',
                resource: 'module_help_center.learning_center.tutorials',
            },
            {
                icon: 'AlertCircleIcon',
                title: 'Add Tutorial',
                icon: 'LayersIcon',
                action: 'index',
                resource: 'module_help_center.learning_center.add_tutorial',
                children: [
                    {
                        title: 'Add Categories',
                        route: 'help-center-types-tutorials',
                        action: 'index',
                        resource: 'module_help_center.learning_center.add_tutorial.add_categories',
                    },
                    {
                        title: 'Create',
                        route: 'help-center-create-tutorials',
                        action: 'index',
                        resource: 'module_help_center.learning_center.add_tutorial.create',
                    },
                ]
            },
            {
                title: 'Banners',
                route: 'template',
                icon: 'ImageIcon',
                action: 'index',
                resource: 'module_help_center.learning_center.banners',
            },
        ]
    },
    {
        title: 'Goal Score',
        tagVariant: 'light-primary',
        icon: 'SmileIcon',
        route: 'help-center-goal-score',
        action: 'index',
        resource: 'module_help_center.goal_score',
    },
    {
        title: 'Blog',
        tagVariant: 'light-primary',
        icon: 'BookmarkIcon',
        isExternalLink: true,
        route: 'https://blog.clinic360management.com/',
        action: 'index',
        resource: 'module_help_center.blog',
    },
]