export default {
  pages: {
    key: 'title',
    data: [
      //dashboard
      {
        title: 'Dashboard Analytics', route: { name: 'dashboard-analytics' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_dashboard'
        }
      },

      //Patient Module
      {
        title: 'Patient', route: { name: 'patients' }, icon: 'UsersIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_patient.patient'
        }
      },
      {
        title: 'Resource Schedule', route: { name: 'resource-schedule' }, icon: 'CalendarIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_patient.resource_schedule'
        }
      },
      {
        title: 'Office Visit', route: { name: 'office-visit' }, icon: 'ListIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_patient.office_visit'
        }
      },

      //Payment Module
      {
        title: 'Invoice List', route: { name: 'resource-schedule' }, icon: 'FileTextIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_payment.invoice_list'
        }
      },
      {
        title: 'Run Closing', route: { name: 'payment-cash-account' }, icon: 'RefreshCwIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_payment.daily_closing.run_closing'
        }
      },
      {
        title: 'Daily Boxes', route: { name: 'payment-cash-daily-boxes' }, icon: 'InboxIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_payment.daily_closing.daily_boxes'
        }
      },
      {
        title: 'Refunds', route: { name: 'payment-refund' }, icon: 'ToggleRightIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_payment.refunds'
        }
      },

      //Module Admin
      {
        title: 'Promotions', route: { name: 'module-promotion' }, icon: 'GiftIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_modules.type_of_modules.promotions'
        }
      },
      {
        title: 'Survey', route: { name: 'module-survey' }, icon: 'FileTextIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_modules.type_of_modules.survey'
        }
      },
      {
        title: 'Delivery Supplies', route: { name: 'module-survey' }, icon: 'FileTextIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_modules.type_of_modules.survey'
        }
      },
      {
        title: 'Types of Membership', route: { name: 'module-memberships-types' }, icon: 'GridIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_modules.type_of_modules.memberships.types'
        }
      },
      {
        title: 'Lots', route: { name: 'module-memberships-lotes' }, icon: 'GridIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_modules.type_of_modules.memberships.lotes'
        }
      },
      {
        title: 'Codes', route: { name: 'module-memberships-code' }, icon: 'GridIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_modules.type_of_modules.memberships.codes'
        }
      },
      {
        title: 'Membership', route: { name: 'module-memberships' }, icon: 'GridIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_modules.type_of_modules.memberships.membership'
        }
      },
      {
        title: 'Users Membership', route: { name: 'module-memberships-users' }, icon: 'GridIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_modules.type_of_modules.memberships.users'
        }
      },

      //CRM 360 Module
      {
        title: 'Boards', route: { name: 'crm-board' }, icon: 'ColumnsIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_360crm.boards'
        }
      },
      {
        title: 'CRM Labels', route: { name: 'crm-admin-labels' }, icon: 'ColumnsIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_360crm.crm_admin.labels'
        }
      },
      {
        title: 'CRM Card Status', route: { name: 'crm-admin-card-status' }, icon: 'PieChartIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_360crm.crm_admin.card_status'
        }
      },
      {
        title: 'CRM Boards', route: { name: 'crm-admin-boards' }, icon: 'ColumnsIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_360crm.crm_admin.board'
        }
      },
      {
        title: 'CRM Columns', route: { name: 'crm-admin-columns' }, icon: 'LayoutIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_360crm.crm_admin.column'
        }
      },

      //Data and Reports Module
      {
        title: 'Admin Data', route: { name: 'report-admin-data' }, icon: 'BarChart2Icon', isBookmarked: false, can: {
          action: 'index', resource: 'module_data_and_reports.admin_data'
        }
      },

      //Documents and Forms Module
      {
        title: 'View Form', route: { name: 'document-form-view' }, icon: 'EyeIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_documents_and_forms.forms.view_form'
        }
      },
      {
        title: 'Edit Form', route: { name: 'document-form-admin-edit' }, icon: 'Edit3Icon', isBookmarked: false, can: {
          action: 'index', resource: 'module_documents_and_forms.forms.edit_form'
        }
      },

      //Help Center Module
      {
        title: '360 Tutorials', route: { name: 'help-center-tutorial' }, icon: 'BookIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_help_center.learning_center.360tutorials'
        }
      },
      {
        title: 'Tutorial', route: { name: 'help-center-information' }, icon: 'FilmIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_help_center.learning_center.tutorials'
        }
      },
      {
        title: 'Create Categories Tutorial', route: { name: 'help-center-types-tutorials' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_help_center.learning_center.add_tutorial.add_categories'
        }
      },
      {
        title: 'Create Tutorial', route: { name: 'help-center-create-tutorials' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_help_center.learning_center.add_tutorial.create'
        }
      },
      {
        title: 'Goal Score', route: { name: 'help-center-goal-score' }, icon: 'SmileIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_help_center.goal_score'
        }
      },
      {
        title: 'Blog', route: { name: 'help-center-goal-score' }, icon: 'BookmarkIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_help_center.blog'
        }
      },

      //Web Settings Module
      {
        title: 'Types of Users', route: { name: 'web-settings-users' }, icon: 'UsersIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_user.type_of_user'
        }
      },
      {
        title: 'User Roles', route: { name: 'web-settings-roles' }, icon: 'ShieldIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_user.user_roles'
        }
      },
      {
        title: 'Promoters', route: { name: 'web-settings-promoter' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_user.promoters'
        }
      },
      {
        title: 'Goals', route: { name: 'web-settings-goals' }, icon: 'AwardIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_user.goals'
        }
      },
      {
        title: 'Goals Types', route: { name: 'web-settings-types-goals' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_user.goal_types'
        }
      },
      {
        title: 'Goal Quantifications', route: { name: 'web-settings-quantifications' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_user.goal_quantifications'
        }
      },
      {
        title: 'Goal Score', route: { name: 'web-settings-goal-score' }, icon: 'AwardIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_user.goal_score'
        }
      },
      {
        title: 'Types of Facilities', route: { name: 'web-settings-facilities' }, icon: 'HomeIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_facility.facilities'
        }
      },
      {
        title: 'Regions', route: { name: 'web-settings-regions' }, icon: 'MapIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_facility.regions'
        }
      },
      {
        title: 'State', route: { name: 'web-settings-states' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_facility.states'
        }
      },
      {
        title: 'Cities', route: { name: 'web-settings-cities' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_facility.cities'
        }
      },
      {
        title: 'Types of visits', route: { name: 'web-settings-visit' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_visit.types_of_visit'
        }
      },
      {
        title: 'Visit Reason', route: { name: 'web-settings-consult' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_visit.visit_reason'
        }
      },
      {
        title: 'Visit Status', route: { name: 'web-settings-visit-status' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_visit.visit_status'
        }
      },
      {
        title: 'Review System', route: { name: 'web-settings-review-system' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_subject.review_system'
        }
      },
      {
        title: 'Review Category', route: { name: 'web-settings-category-review-system' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_subject.review_system_category'
        }
      },
      {
        title: 'Treatments', route: { name: 'web-settings-treatment' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_treatments.treatments'
        }
      },
      {
        title: 'Diagnoses', route: { name: 'web-settings-diagnoses' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_diagnoses.diagnoses'
        }
      },
      {
        title: 'Medication', route: { name: 'web-settings-medication' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_form.medications'
        }
      },
      {
        title: 'Allergies', route: { name: 'web-settings-allergies' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_form.allergies'
        }
      },
      {
        title: 'Ailments', route: { name: 'web-settings-ailment' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.admin_form.ailments'
        }
      },
      {
        title: 'IH Tests', route: { name: 'web-settings-test' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.in_house.ih_tests'
        }
      },
      {
        title: 'IH Procedures', route: { name: 'web-settings-procedures' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.in_house.ih_procedures'
        }
      },
      {
        title: 'Laboratory category', route: { name: 'web-settings-category_lab' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.laboratory.categories'
        }
      },
      {
        title: 'Laboratory', route: { name: 'web-settings-laboratory' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.laboratory.laboratories'
        }
      },
      {
        title: 'Diagnostic image', route: { name: 'web-settings-diagnostic-image' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.imaging.diagnostic_imaging'
        }
      },
      {
        title: 'Diagnostic imaging categories', route: { name: 'web-settings-diagnostic-imaging-category' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.imaging.categories'
        }
      },
      {
        title: 'Consult Price', route: { name: 'web-settings-consult-price' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.consult_price'
        }
      },
      {
        title: 'Template', route: { name: 'web-settings-template' }, icon: 'ActivityIcon', isBookmarked: false, can: {
          action: 'index', resource: 'module_system_settings.settings.web_settings.template'
        }
      },
    ],
  },

  contacts: {
    key: 'name',
    data: []
    /* {
      img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
      name: 'Rena Brant',
      email: 'nephrod@preany.co.uk',
      time: '21/05/2019',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
      name: 'Mariano Packard',
      email: 'seek@sparaxis.org',
      time: '14/01/2018',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
      name: 'Risa Montufar',
      email: 'vagary@unblist.org',
      time: '10/08/2019',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
      name: 'Maragaret Cimo',
      email: 'designed@insanely.net',
      time: '01/12/2019',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
      name: 'Jona Prattis',
      email: 'unwieldable@unblist.org',
      time: '21/05/2019',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-5.jpg'),
      name: 'Edmond Chicon',
      email: 'museist@anaphyte.co.uk',
      time: '15/11/2019',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
      name: 'Abbey Darden',
      email: 'astema@defectively.co.uk',
      time: '07/05/2019',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
      name: 'Seema Moallankamp',
      email: 'fernando@storkish.co.uk',
      time: '13/08/2017',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
      name: 'Charleen Warmington',
      email: 'furphy@cannibal.net',
      time: '11/08/1891',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
      name: 'Geri Linch',
      email: 'insignia@markab.org',
      time: '18/01/2015',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
      name: 'Shellie Muster',
      email: 'maxillary@equalize.co.uk',
      time: '26/07/2019',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
      name: 'Jesenia Vanbramer',
      email: 'hypotony@phonetist.net',
      time: '12/09/2017',
    },
    {
      img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
      name: 'Mardell Channey',
      email: 'peseta@myrica.com',
      time: '11/11/2019',
    }, */
  },
}
/* eslint-enable */
