export default [
    {
        header: 'Patient',
        action: 'index',
        resource: 'module_patient',
    },
    {
        title: 'Add Patient', //abrir modal automatico
        icon: 'UsersIcon',
        tagVariant: 'light-primary',
        route: 'patients-add',
        action: 'store',
        resource: 'module_patient.patient', //quitar a la nurse
    },
    {
        title: 'Patient List',
        icon: 'UsersIcon',
        tagVariant: 'light-primary',
        route: 'patients',
        action: 'index',
        resource: 'module_patient.patient',
    },
    {
        title: 'Resource Schedule',
        icon: 'CalendarIcon',
        tagVariant: 'light-primary',
        route: 'resource-schedule',
        action: 'index',
        resource: 'module_patient.resource_schedule',
    },
    {
        title: 'Office Visit',
        icon: 'ListIcon',
        tagVariant: 'light-primary',
        route: 'office-visit',
        action: 'index',
        resource: 'module_patient.office_visit',
    },
]