export default [
    {
        header: 'Documents and Forms',
        action: 'index',
        resource: 'module_documents_and_forms',
    },
    {
        title: 'Forms',
        icon: 'EditIcon',
        tagVariant: 'light-primary',
        action: 'index',
        resource: 'module_documents_and_forms.forms',
        children: [
            {
                title: 'View Form',
                route: 'document-form-view',
                icon: 'EyeIcon',
                action: 'index',
                resource: 'module_documents_and_forms.forms.view_form',
            },
            {
                title: 'Edit Form',
                route: 'document-form-admin-edit',
                icon: 'Edit3Icon',
                action: 'index',
                resource: 'module_documents_and_forms.forms.edit_form',
            },
        ]
    },
]