<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow bg-sidebar"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <div class="nav-item row justify-content-end px-1 pt-1">
          <b-link class="nav-link modern-nav-toggle">
            <feather-icon
              icon="XIcon"
              size="24"
              class="d-block d-xl-none"
              @click="toggleVerticalMenuActive"
            />
            <feather-icon
              :icon="collapseTogglerIconFeather"
              size="24"
              class="d-none d-xl-block collapse-toggle-icon mr-0"
              @click="toggleCollapsed"
            />
          </b-link>
        </div>
        <div
          class="nav-item row"
          :class="{
            'justify-content-center':
              !isVerticalMenuCollapsed ||
              (isVerticalMenuCollapsed && isMouseHovered),
          }"
          style="position: relative; bottom: 1rem; padding-left: 0.5rem"
        >
          <b-link class="navbar-brand mr-0" to="/">
            <span class="brand-logo">
              <b-img :src="appLogoImage" alt="logo" />
            </span>
          </b-link>
        </div>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        v-if="isMenuMedicalCenter"
        :items="navMenuItemsMedicalCenter"
        class="navigation navigation-main bg-sidebar"
      />
      <vertical-nav-menu-items
        v-else
        :items="navMenuItems"
        class="navigation navigation-main bg-sidebar"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
    <div class="w-100 text-center mt-1">
      <b-img
        src="@/assets/images/logo/logoInnova_horizontal_white.svg"
        alt="Login V2"
        width="100px"
        class="img-fluid mx-auto"
      />
    </div>
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import navMenuItemsMedicalCenter from "@/navigation/vertical/medicalCenter";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    navMenuItemsMedicalCenter,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  watch: {
    "$variableGlobal.isMedicalCenter": function (value) {
      this.isMenuMedicalCenter = value;
    },
  },
  mounted() {
    const facilityInUse = this.$variableGlobal.isMedicalCenter;
    if (facilityInUse) {
      this.isMenuMedicalCenter = true;
    }
  },
  data() {
    return {
      isMenuMedicalCenter: false,
    };
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const appLogoImage = require("@/assets/images/logo/logo360_verticalWhite.svg");
    const { appName } = $themeConfig.app;

    return {
      navMenuItems,
      navMenuItemsMedicalCenter,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
